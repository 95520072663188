export default function Baccarat(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" {...props} data-sentry-element="svg" data-sentry-component="Baccarat" data-sentry-source-file="Baccarat.jsx">
      <g clipPath="url(#clip0_2952_155017)" data-sentry-element="g" data-sentry-source-file="Baccarat.jsx">
        <path d="M15.9053 1.1507L9.49481 0.0203611C8.77381 -0.106774 8.08623 0.374672 7.9591 1.09568L7.89844 1.43976C8.8255 1.55398 9.60873 2.22183 9.85533 3.14205L10.2471 4.60415C10.4545 4.5142 10.6897 4.4818 10.929 4.524C11.3831 4.60409 11.7315 4.9309 11.8613 5.34219C12.1239 5.0001 12.5631 4.81214 13.0172 4.89222C13.6672 5.00684 14.1012 5.62664 13.9866 6.27659C13.7375 7.68886 12.222 8.82397 11.5028 9.29084L12.6009 13.3889C12.6598 13.6085 12.6843 13.8293 12.6789 14.046L13.6027 14.2089C14.3237 14.336 15.0113 13.8545 15.1384 13.1335L16.9806 2.68647C17.1077 1.9654 16.6263 1.27783 15.9053 1.1507Z" fill="#7179A5" data-sentry-element="path" data-sentry-source-file="Baccarat.jsx" />
        <path d="M8.89403 3.39987C8.73532 2.80759 8.19972 2.41696 7.61438 2.41699C7.50083 2.41699 7.38535 2.4317 7.27043 2.46248L0.982889 4.14721C0.275696 4.3367 -0.143992 5.06361 0.0454985 5.77081L2.7911 16.0176C2.94981 16.6099 3.48541 17.0005 4.07075 17.0005C4.1843 17.0005 4.29978 16.9858 4.41466 16.955L10.7022 15.2702C11.4094 15.0807 11.8291 14.3538 11.6396 13.6466L8.89403 3.39987ZM4.00132 10.4041C3.8947 10.3103 3.85489 10.1617 3.90031 10.0271L4.86174 7.18038C4.94216 6.94225 5.23906 6.86269 5.42779 7.02871L7.68381 9.01339C7.79043 9.10719 7.83024 9.25584 7.78481 9.39035L6.82339 12.2371C6.74297 12.4752 6.44606 12.5548 6.25734 12.3888L4.00132 10.4041Z" fill="#7179A5" data-sentry-element="path" data-sentry-source-file="Baccarat.jsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="Baccarat.jsx">
        <clipPath id="clip0_2952_155017" data-sentry-element="clipPath" data-sentry-source-file="Baccarat.jsx">
          <rect width="17" height="17" fill="white" data-sentry-element="rect" data-sentry-source-file="Baccarat.jsx" />
        </clipPath>
      </defs>
    </svg>;
}